




































import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import { Vue, Component, Prop } from "vue-property-decorator";
import { HomeProperties } from "../HomeView.vue";

const ConfirmedOrderAView = () => import("./ConfirmedOrderAView.vue");
const PendingOrderAView = () => import("./PendingOrderAView.vue");

class MainProperties {
    loading: boolean = false;
}

export { MainProperties };

@Component({
	metaInfo: {
		link: [{ rel: "stylesheet", href: "/home-main.css" }]
	},
    data: () => ({
        tab: null
    }),
    components: {
        ConfirmedOrderAView,
        PendingOrderAView
    },
})
export default class MainView extends Vue {
    @Prop() private home: HomeProperties;
    @Prop() private alertDialog: AlertDialogProperties;

    private properties = new MainProperties();

    public get title() {
        if (this.$data.tab === 0) {
            return this.$t("text.order");
        } else if (this.$data.tab === 1) {
            return this.$t("text.pending");
        }
        return "-";
    }
}
